import React from "react"

export default function hero() {
  return (
    <section class="relative home-wrapper items-center overflow-hidden">
      <div class="section wf-section">
        <div class="section-container-lg w-container">
          <div className="tabs-4 w-tabs">
            <div className="tabs-content-5 w-tab-content">
              <div class="w-tab-pane w--tab-active">
                <div class="pricing-card-container">
                  <div class="pricing-card-container">
                    <div class="div-block-460 _2">
                      <div class="new-pricing-card">
                        <div class="new-pricing-card-left">
                          <div class="pricing-card-category">
                            Launch Turkey Start
                          </div>
                          <div class="pricing-card-title-container">
                            <h2 class="pricing-card-heading">
                              Start a Turkish business
                            </h2>
                            <p class="pricing-card-description">
                              Incorporate your LTD or Joint-Stock.
                            </p>
                          </div>
                        </div>
                        <div class="new-pricing-card-left-copy">
                          <div class="pricing-card-category-copy">
                            Includes:
                          </div>
                          <ul
                            role="list"
                            class="pricing-card-list w-list-unstyled"
                          >
                            <li class="pricing-card-list-item">
                              <img
                                loading="lazy"
                                src="https://assets.website-files.com/609486d2fafa37698a57db5b/6230cd2d68535e66bb5219dd_square-check.svg"
                                alt=""
                                class="image-123"
                              />
                              <div>
                                Formation of company in Istanbul, Adana, Antalya, or Bursa
                              </div>
                            </li>
                            <li class="pricing-card-list-item">
                              <img
                                loading="lazy"
                                src="https://assets.website-files.com/609486d2fafa37698a57db5b/6230cd2d68535e66bb5219dd_square-check.svg"
                                alt=""
                                class="image-123"
                              />
                              <div>
                                All filing fees &amp; expedited processing
                              </div>
                            </li>
                            <li class="pricing-card-list-item">
                              <img
                                loading="lazy"
                                src="https://assets.website-files.com/609486d2fafa37698a57db5b/6230cd2d68535e66bb5219dd_square-check.svg"
                                alt=""
                                class="image-123"
                              />
                              <div>
                                Open a business bank account
                              </div>
                            </li>
                            <li class="pricing-card-list-item">
                              <img
                                loading="lazy"
                                src="https://assets.website-files.com/609486d2fafa37698a57db5b/6230cd2d68535e66bb5219dd_square-check.svg"
                                alt=""
                                class="image-123"
                              />
                              <div>
                                Expedited Tax ID setup
                                <strong>
                                  <br />
                                </strong>
                              </div>
                            </li>
                            <li class="pricing-card-list-item">
                              <img
                                loading="lazy"
                                src="https://assets.website-files.com/609486d2fafa37698a57db5b/6230cd2d68535e66bb5219dd_square-check.svg"
                                alt=""
                                class="image-123"
                              />
                              <div>All essential and important documents</div>
                            </li>
                            <li class="pricing-card-list-item">
                              <img
                                loading="lazy"
                                src="https://assets.website-files.com/609486d2fafa37698a57db5b/6230cd2d68535e66bb5219dd_square-check.svg"
                                alt=""
                                class="image-123"
                              />
                              <div>
                                Payment processing, payroll, and accounting via
                                Loop
                              </div>
                            </li>
                            <li class="pricing-card-list-item">
                              <img
                                loading="lazy"
                                src="https://assets.website-files.com/609486d2fafa37698a57db5b/6230cd2d68535e66bb5219dd_square-check.svg"
                                alt=""
                                class="image-123"
                              />
                              <div>Lifetime expert support</div>
                            </li>
                          </ul>
                          <div class="text-block-123">
                          </div>
                        </div>
                        <div class="new-pricing-card-right">
                          <div class="div-block-461">
                            <div class="price text-white">$300 </div>
                            <div class="text-span-28">/PER MONTH - Subscribe for 1 year</div>
                          </div>
                        </div>
                      </div>
                    </div>



                    <div class="div-block-460 _2">
                      <div class="new-pricing-card">
                        <div class="new-pricing-card-left">
                          <div class="pricing-card-category">
                            Launch Turkey PEO
                          </div>
                          <div class="pricing-card-title-container">
                            <h2 class="pricing-card-heading">
                              Hire in Turkiye
                            </h2>
                            <p class="pricing-card-description">
                              Without a Turkish entity.
                            </p>
                          </div>
                        </div>
                        <div class="new-pricing-card-left-copy">
                          <div class="pricing-card-category-copy">
                            Includes:
                          </div>
                          <ul
                            role="list"
                            class="pricing-card-list w-list-unstyled"
                          >
                            <li class="pricing-card-list-item">
                              <img
                                loading="lazy"
                                src="https://assets.website-files.com/609486d2fafa37698a57db5b/6230cd2d68535e66bb5219dd_square-check.svg"
                                alt=""
                                class="image-123"
                              />
                              <div>
                                Employee onboarding
                              </div>
                            </li>
                            <li class="pricing-card-list-item">
                              <img
                                loading="lazy"
                                src="https://assets.website-files.com/609486d2fafa37698a57db5b/6230cd2d68535e66bb5219dd_square-check.svg"
                                alt=""
                                class="image-123"
                              />
                              <div>
                                Turkiye Remote Work Regulation compliance
                              </div>
                            </li>
                            <li class="pricing-card-list-item">
                              <img
                                loading="lazy"
                                src="https://assets.website-files.com/609486d2fafa37698a57db5b/6230cd2d68535e66bb5219dd_square-check.svg"
                                alt=""
                                class="image-123"
                              />
                              <div>
                                Employment agreement
                              </div>
                            </li>
                            <li class="pricing-card-list-item">
                              <img
                                loading="lazy"
                                src="https://assets.website-files.com/609486d2fafa37698a57db5b/6230cd2d68535e66bb5219dd_square-check.svg"
                                alt=""
                                class="image-123"
                              />
                              <div>
                                Monthly payroll loop
                                <strong>
                                  <br />
                                </strong>
                              </div>
                            </li>
                            <li class="pricing-card-list-item">
                              <img
                                loading="lazy"
                                src="https://assets.website-files.com/609486d2fafa37698a57db5b/6230cd2d68535e66bb5219dd_square-check.svg"
                                alt=""
                                class="image-123"
                              />
                              <div>Salary payments</div>
                            </li>
                            <li class="pricing-card-list-item">
                              <img
                                loading="lazy"
                                src="https://assets.website-files.com/609486d2fafa37698a57db5b/6230cd2d68535e66bb5219dd_square-check.svg"
                                alt=""
                                class="image-123"
                              />
                              <div>
                                HR tracking
                              </div>
                            </li>
                            <li class="pricing-card-list-item">
                              <img
                                loading="lazy"
                                src="https://assets.website-files.com/609486d2fafa37698a57db5b/6230cd2d68535e66bb5219dd_square-check.svg"
                                alt=""
                                class="image-123"
                              />
                              <div>Turkish Labour Law compliance</div>
                            </li>
                          </ul>
                          <div class="text-block-123">
                          </div>
                        </div>
                        <div class="new-pricing-card-right">
                          <div class="div-block-461">
                            <div class="price text-white">$200 </div>
                            <div class="text-span-28">/per month</div>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="div-block-460 _2">
                      <div class="new-pricing-card">
                        <div class="new-pricing-card-left">
                          <div class="pricing-card-category">
                            Launch Turkey Mailroom
                          </div>
                          <div class="pricing-card-title-container">
                            <h2 class="pricing-card-heading">
                                Get a Turkiye business address
                            </h2>
                            <p class="pricing-card-description">
                                A premium address and virtual mailbox.
                            </p>
                          </div>
                        </div>
                        <div class="new-pricing-card-left-copy">
                          <div class="pricing-card-category-copy">
                            Includes:
                          </div>
                          <ul
                            role="list"
                            class="pricing-card-list w-list-unstyled"
                          >
                            <li class="pricing-card-list-item">
                              <img
                                loading="lazy"
                                src="https://assets.website-files.com/609486d2fafa37698a57db5b/6230cd2d68535e66bb5219dd_square-check.svg"
                                alt=""
                                class="image-123"
                              />
                              <div>
                                One business address in your city of choice
                              </div>
                            </li>
                            <li class="pricing-card-list-item">
                              <img
                                loading="lazy"
                                src="https://assets.website-files.com/609486d2fafa37698a57db5b/6230cd2d68535e66bb5219dd_square-check.svg"
                                alt=""
                                class="image-123"
                              />
                              <div>
                                Unlimited incoming mail, scans, recycling
                              </div>
                            </li>
                            <li class="pricing-card-list-item">
                              <img
                                loading="lazy"
                                src="https://assets.website-files.com/609486d2fafa37698a57db5b/6230cd2d68535e66bb5219dd_square-check.svg"
                                alt=""
                                class="image-123"
                              />
                              <div>
                                Unlimited digital storage
                              </div>
                            </li>
                            <li class="pricing-card-list-item">
                              <img
                                loading="lazy"
                                src="https://assets.website-files.com/609486d2fafa37698a57db5b/6230cd2d68535e66bb5219dd_square-check.svg"
                                alt=""
                                class="image-123"
                              />
                              <div>
                                Shipping available from all major carriers
                                <strong>
                                  <br />
                                </strong>
                              </div>
                            </li>
                          </ul>
                          <div class="text-block-123">Without a valid Turkiye address, Mailroom is required for new incorporation customers.<br/>
                          </div>
                        </div>
                        <div class="new-pricing-card-right">
                          <div class="div-block-461">
                            <div class="price text-white">$35 </div>
                            <div class="text-span-28">/per month</div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
